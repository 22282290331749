<template>
	<div id="CourseCourse">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>常规</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">公开课申报</a></el-breadcrumb-item>
			</el-breadcrumb>
			
			<div class="content u-f">
				<div class="content-left">
					<div class="u-f-item meun-item" :class="meunLeftIndex == 0 ? 'meun-active' : ''" @click="meunLeftTap(0)">
						<div class="iconfont icon-fabu"></div>
						<div>我的申请</div>
					</div>
					<div class="u-f-item meun-item" :class="meunLeftIndex == 1 ? 'meun-active' : ''" @click="meunLeftTap(1)">
						<div class="iconfont icon-caogao"></div>
						<div>抄送给我</div>
					</div>
					<div class="font_blod font_16 p-t-b">审核</div>
					<div class="u-f-item meun-item" :class="meunLeftIndex == 2 ? 'meun-active' : ''" @click="meunLeftTap(2)">
						<div class="iconfont icon-yishenhe"></div>
						<div>未审核</div>
					</div>
					<div class="u-f-item meun-item" :class="meunLeftIndex == 3 ? 'meun-active' : ''" @click="meunLeftTap(3)">
						<div class="iconfont icon-yishenhe1"></div>
						<div>已审核</div>
					</div>
				</div>
				<!-- 我的申请 -->
				<div class="content-right" v-if="meunLeftIndex == 0">
					<div class="title u-f-item u-f-jsb">
						<div>
							共{{ total }}条数据
							<!-- <span class="btn" @click="selectAll">选择全部</span> -->
						</div>
						<div class="u-f-item">
							<!-- <el-input placeholder="请输入内容" v-model="sousuo" size="small" style="margin-right: 10px;" @change="sousuoList"> -->
							<!-- <i slot="prefix" class="el-input__icon el-icon-search"></i> -->
							<!-- </el-input> -->
							<!-- <el-button size="small" @click="activityVisible = true">第二课堂申请</el-button> -->
						</div>
					</div>
					<el-table
						:data="Mylist"
						height="90%"
						ref="multipleTable"
						tooltip-effect="dark"
						style="width: 100%;border: 1px solid #EEEEEE;"
						key="2"
					>
						<el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
						<el-table-column prop="plan" label="教学计划" align="center"></el-table-column>
						<el-table-column prop="media_img" label="功能室登记表" align="center">
							<template slot-scope="scope">
								<div class="u-f-item">
									<i class="iconfont icon-excel" style="color: red;margin-right: 5px;"></i>
									<a :href="'http://school.blzzxx.cn'+scope.row.media_img">功能室登记表</a>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="attendance_img" label="考勤表" align="center">
							<template slot-scope="scope">
								<div class="u-f-item">
									<i class="iconfont icon-excel" style="color: red;margin-right: 5px;"></i>
									<a :href="'http://school.blzzxx.cn'+scope.row.attendance_img">考勤表</a>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="activity_img" label="活动记录表" align="center">
							<template slot-scope="scope">
								<div class="u-f-item">
									<i class="iconfont icon-excel" style="color: red;margin-right: 5px;"></i>
									<a :href="'http://school.blzzxx.cn'+scope.row.activity_img">活动记录表</a>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="give_img" label="授课照片" align="center">
							<template slot-scope="scope">
								<el-image 
									fit="cover"
								    style="width: 50px; height: 50px"
								    :src="'http://school.blzzxx.cn'+scope.row.give_img" 
								    :preview-src-list="['http://school.blzzxx.cn'+scope.row.give_img]">
								</el-image>
							</template>
						</el-table-column>
						<el-table-column prop="success_img" label="成功照片" align="center">
							<template slot-scope="scope">
								<el-image
									fit="cover"
								    style="width: 50px; height: 50px"
								    :src="'http://school.blzzxx.cn'+scope.row.success_img" 
								    :preview-src-list="['http://school.blzzxx.cn'+scope.row.success_img]">
								</el-image>
							</template>
						</el-table-column>
						<el-table-column prop="status" label="审核状态" align="center">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.status == 1">待审核</el-tag>
								<el-tag v-if="scope.row.status == 2">已通过</el-tag>
								<el-tag v-if="scope.row.status == 3">未通过</el-tag>
							</template>
						</el-table-column>
						<el-table-column align="center" label="操作" width="150">
							<template slot-scope="scope">
								<el-button @click="openDetails(scope.row)" type="text" size="small">详情</el-button>
								<!-- <el-button @click="delcourse(scope.row.id, scope.row.time_id)" type="text" size="small" style="color:red;">撤回</el-button> -->
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
						<div class="u-f-item"></div>
						<!-- <el-button type="danger" plain size="mini" @click="delActivit">删除</el-button> -->
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
				<!-- 我的抄送 -->
				<div class="content-right" v-if="meunLeftIndex != 0">
					<div class="title u-f-item">
						<div>
							共{{ total }}条数据
						</div>
					</div>
					<el-table
						:data="copyToList"
						height="90%"
						ref="multipleTable"
						tooltip-effect="dark"
						style="width: 100%;border: 1px solid #EEEEEE;"
						key="2"
					>
						<el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
						<el-table-column prop="plan" label="教学计划" align="center"></el-table-column>
						<el-table-column prop="media_img" label="功能室登记表" align="center">
							<template slot-scope="scope">
								<div class="u-f-item">
									<i class="iconfont icon-excel" style="color: red;margin-right: 5px;"></i>
									<a :href="'http://school.blzzxx.cn'+scope.row.media_img">功能室登记表</a>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="attendance_img" label="考勤表" align="center">
							<template slot-scope="scope">
								<div class="u-f-item">
									<i class="iconfont icon-excel" style="color: red;margin-right: 5px;"></i>
									<a :href="'http://school.blzzxx.cn'+scope.row.attendance_img">考勤表</a>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="activity_img" label="活动记录表" align="center">
							<template slot-scope="scope">
								<div class="u-f-item">
									<i class="iconfont icon-excel" style="color: red;margin-right: 5px;"></i>
									<a :href="'http://school.blzzxx.cn'+scope.row.activity_img">活动记录表</a>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="give_img" label="授课照片" align="center">
							<template slot-scope="scope">
								<el-image 
									fit="cover"
								    style="width: 50px; height: 50px"
								    :src="'http://school.blzzxx.cn'+scope.row.give_img" 
								    :preview-src-list="['http://school.blzzxx.cn'+scope.row.give_img]">
								</el-image>
							</template>
						</el-table-column>
						<el-table-column prop="success_img" label="成功照片" align="center">
							<template slot-scope="scope">
								<el-image
									fit="cover"
								    style="width: 50px; height: 50px"
								    :src="'http://school.blzzxx.cn'+scope.row.give_img" 
								    :preview-src-list="['http://school.blzzxx.cn'+scope.row.success_img]">
								</el-image>
							</template>
						</el-table-column>
						<el-table-column prop="status" label="审核状态" align="center">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.status == 1">待审核</el-tag>
								<el-tag v-if="scope.row.status == 2">已通过</el-tag>
								<el-tag v-if="scope.row.status == 3">未通过</el-tag>
							</template>
						</el-table-column>
						<el-table-column align="center" label="操作" width="150">
							<template slot-scope="scope">
								<el-button @click="openDetails(scope.row)" type="text" size="small">详情</el-button>
								<!-- <el-button @click="delcourse(scope.row.id, scope.row.time_id)" type="text" size="small" style="color:red;">撤回</el-button> -->
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
						<div class="u-f-item"></div>
						<!-- <el-button type="danger" plain size="mini" @click="delActivit">删除</el-button> -->
						<el-pagination
							@size-change="handleSizeChange1"
							@current-change="handleCurrentChange1"
							:current-page.sync="page1"
							:page-size="limit1"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
			</div>
			
			<!-- 详情 -->
			<el-dialog title="公开课申报" :visible.sync="activityVisible1" width="650px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f input-box">
						<div class="input-lebal">学校计划：</div>
						<div>{{ info.plan }}</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">功能室登记表：</div>
						<div class="u-f-item">
							<i class="iconfont icon-excel" style="color: red;margin-right: 5px;"></i>
							<a target="_blank" :href="'http://view.officeapps.live.com/op/view.aspx?src=http://school.blzzxx.cn' + info.media_img">功能室登记表</a>
						</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">考勤表：</div>
						<div class="u-f-item">
							<i class="iconfont icon-excel" style="color: red;margin-right: 5px;"></i>
							<a target="_blank" :href="'http://view.officeapps.live.com/op/view.aspx?src=http://school.blzzxx.cn' + info.attendance_img">考勤表</a>
						</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">活动记录表：</div>
						<div class="u-f-item">
							<i class="iconfont icon-excel" style="color: red;margin-right: 5px;"></i>
							<a target="_blank" :href="'http://view.officeapps.live.com/op/view.aspx?src=http://school.blzzxx.cn' + info.activity_img">活动记录表</a>
						</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">授课照片：</div>
						<div class="u-f-item">
							<el-image
								fit="cover"
								style="width: 80px; height: 80px"
								:src="'http://school.blzzxx.cn' + info.give_img"
								:preview-src-list="['http://school.blzzxx.cn' + info.give_img]"
							></el-image>
						</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">成功照片：</div>
						<div class="u-f-item">
							<el-image
								fit="cover"
								style="width: 80px; height: 80px"
								:src="'http://school.blzzxx.cn' + info.success_img"
								:preview-src-list="['http://school.blzzxx.cn' + info.success_img]"
							></el-image>
						</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">课堂总结：</div>
						<div>{{ info.summarize }}</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">申请人：</div>
						<div>{{ info.petitioner_name }}</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">审核状态：</div>
						<div>{{ info.status_text }}</div>
					</div>
					<div class="u-f-item input-box" v-if="info.audit_opinions">
						<div class="input-lebal">审核备注：</div>
						<div>{{ info.audit_opinions }}</div>
					</div>
				</div>
				<div style="min-height: 200px;padding-left: 60px;margin-top: 40px;">
					<el-steps direction="vertical">
						<el-step title="审核人" v-if="info.audit&&info.audit.length">
							<div slot="description" style="padding-bottom: 10px;">
								<div v-for="(item,index) in info.audit" :key="index" style="margin-top: 10px;">
									<div class="u-f-item u-f-jsb">
										<div>{{item.audit_username}}</div>
										<div v-if="info.status!=4">
											<span v-if="item.handle==0">待上级审批</span>
											<span v-if="item.handle==1">待审批</span>
											<span v-if="item.handle==2">已审批</span>
											<span v-if="item.audit_time">{{item.audit_time|getLocalTime}}</span>
										</div>
										<div v-if="info.status==4">
											<span>已撤回</span>
										</div>
									</div>
									<div>{{item.audit_opinions}}</div>
								</div>
							</div>
						</el-step>
						<el-step title="承办人" v-if="info.exec&&info.exec.length">
							<div slot="description" style="padding-bottom: 10px;">
								<div v-for="(item,index) in info.exec" :key="index" style="margin-top: 10px;">
									<div class="u-f-item u-f-jsb">
										<div>{{item.audit_username}}</div>
										<div v-if="info.status!=4">
											<span v-if="item.handle==0">待上级审批</span>
											<span v-if="item.handle==1">待审批</span>
											<span v-if="item.handle==2">已审批</span>
											<span v-if="item.audit_time">{{item.audit_time|getLocalTime}}</span>
										</div>
										<div v-if="info.status==4">
											<span>已撤回</span>
										</div>
									</div>
									<div>{{item.audit_opinions}}</div>
								</div>
							</div>
						</el-step>
						<el-step title="抄送人" v-if="info.ectype&&info.ectype.length">
							<div slot="description">
								<div v-for="(item,index) in info.ectype" :key="index" style="margin-top: 10px;">
									<div class="u-f-item u-f-jsb">
										<div>{{item.audit_username}}</div>
										<div>
											<span v-if="info.exec_status==2">已抄送</span>
											<span v-if="info.status==4">已撤回</span>
											<span v-if="info.updatetime&&info.exec_status==2">{{info.updatetime|getLocalTime}}</span>
										</div>
									</div>
									<div>{{item.audit_opinions}}</div>
								</div>
							</div>
						</el-step>
					</el-steps>
				</div>
			</el-dialog>
			
			<el-dialog title="公开课申请" :visible.sync="activityVisible" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							学校计划
						</div>
						<el-input v-model="plan" placeholder="请输入学校计划" style="width: 70%;"></el-input>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">
							<span>*</span>
							功能室登记表
						</div>
						<div>
							<el-button size="mini" style="position: relative;">
								<input
									accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
									single
									ref="filElem"
									type="file"
									class="upload-file"
									@change="getFile"
								/>
								点击上传
							</el-button>
							<div class="u-f-item u-f-jsb file_box" v-if="file.name" style="margin-top: 10px;width: 300px;">
								<div class="u-f-item">
									<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>
									{{ file.name }}
								</div>
								<i class="el-icon-circle-check" style="color: #67C23A;"></i>
								<i class="el-icon-circle-close" style="color: #67C23A;" @click="delFile"></i>
							</div>
						</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">
							<span>*</span>
							考勤表
						</div>
						<div>
							<el-button size="mini" style="position: relative;">
								<input
									accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
									single
									ref="filElem1"
									type="file"
									class="upload-file"
									@change="getFile1"
								/>
								点击上传
							</el-button>
							<div class="u-f-item u-f-jsb file_box" v-if="file1.name" style="margin-top: 10px;width: 300px;">
								<div class="u-f-item">
									<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>
									{{ file1.name }}
								</div>
								<i class="el-icon-circle-check" style="color: #67C23A;"></i>
								<i class="el-icon-circle-close" style="color: #67C23A;" @click="delFile1"></i>
							</div>
						</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">
							<span>*</span>
							活动记录表
						</div>
						<div>
							<el-button size="mini" style="position: relative;">
								<input
									accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
									single
									ref="filElem2"
									type="file"
									class="upload-file"
									@change="getFile2"
								/>
								点击上传
							</el-button>
							<div class="u-f-item u-f-jsb file_box" v-if="file2.name" style="margin-top: 10px;width: 300px;">
								<div class="u-f-item">
									<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>
									{{ file2.name }}
								</div>
								<i class="el-icon-circle-check" style="color: #67C23A;"></i>
								<i class="el-icon-circle-close" style="color: #67C23A;" @click="delFile2"></i>
							</div>
						</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">
							<span>*</span>
							授课照片
						</div>
						<div>
							<el-upload
								class="upload-demo"
								action="http://school.blzzxx.cn/v1/school_api/Common/upload"
								:on-success="handlePreview"
								:on-remove="handleRemove"
								list-type="picture"
								:limit="1"
								:file-list="fileList"
							>
								<el-button size="small" type="primary">点击上传</el-button>
								<div slot="tip" class="el-upload__tip">第二课堂授课照片</div>
							</el-upload>
						</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">
							<span>*</span>
							成功照片
						</div>
						<div>
							<el-upload
								class="upload-demo"
								action="http://school.blzzxx.cn/v1/school_api/Common/upload"
								:on-success="handlePreview2"
								:on-remove="handleRemove2"
								list-type="picture"
								:limit="1"
								:file-list="fileList1"
							>
								<el-button size="small" type="primary">点击上传</el-button>
								<div slot="tip" class="el-upload__tip">第二课堂成功照片</div>
							</el-upload>
						</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="margin-top: 10px;">
							<span>*</span>
							课堂总结
						</div>
						<el-input v-model="summarize" type="textarea" :rows="3" placeholder="请输入课堂总结" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							审批部门
						</div>
						<el-select v-model="main_department_id" placeholder="请选择" style="width: 70%;" @change="mainChanage">
							<el-option v-for="item in sectionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							抄送部门
						</div>
						<el-select v-model="ectype_department_id" placeholder="请选择" style="width: 70%;" @change="ectypeChanage">
							<el-option v-for="item in sectionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							审批人
						</div>
						<el-select multiple v-model="audit" placeholder="请选择" style="width: 70%;">
							<el-option v-for="item in peopleList" :key="item.id" :label="item.username" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							抄送人
						</div>
						<el-select multiple v-model="ectype" placeholder="请选择" style="width: 70%;">
							<el-option v-for="item in peopleList2" :key="item.id" :label="item.username" :value="item.id"></el-option>
						</el-select>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeActivity" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit()">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			meunLeftIndex: 0,
			total: 0,
			activityVisible: false,
			main_department_id: '',//审核部门id
			ectype_department_id: '',//抄送人部门ID
			audit: [],//审批人
			ectype: [],//抄送人
			sectionList: [],
			peopleList: [],
			peopleList2: [],
			page: 1,
			limit: 10,
			Mylist: [],
			ids: [],
			type: 1,
			copyToList: [],
			file: '',
			file1: '',
			file2: '',
			plan:'',//学校计划
			give_img:'',//授课照片
			success_img:'',//成功照片
			media_img:'',//功能室登记扫描件
			attendance_img:'',//考勤表扫描件
			activity_img:'',//活动记录表扫描件
			summarize:'',// 课堂总结
			condition: '',
			conditionList: [],
			year: '',
			yearList: [],
			semester: '',
			semesterList: [],
			fileList:[],
			fileList1:[],
			page1:0,
			limit1:10,
			activityVisible1:false,
			info:''
		};
	},
	mounted() {
		this.getYearList();
		this.getSectionList()
	},
	filters:{
		getLocalTime(nS) {  
			return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');  
		}
	},
	methods: {
		getFile(e) {
			if (this.$refs.filElem.files.length) {
				this.file = this.$refs.filElem.files[0];
				let myfile = this.$refs.filElem;
				let files = myfile.files;
				let file = files[0];
				var formData = new FormData();
				formData.append("file", file);
				this.$api.setting.uploadFile(formData).then(res=>{
					if(res.data.code==1){
						this.media_img = res.data.data.url
					}else{
						this.$message.error(res.data.msg)
					}
				})
			}
		},
		delFile() {
			this.file = '';
			this.media_img = '';
			this.$refs.filElem.value = '';
		},
		getFile1(e) {
			if (this.$refs.filElem1.files.length) {
				this.file1 = this.$refs.filElem1.files[0];
				let myfile = this.$refs.filElem1;
				let files = myfile.files;
				let file = files[0];
				var formData = new FormData();
				formData.append("file", file);
				this.$api.setting.uploadFile(formData).then(res=>{
					if(res.data.code==1){
						this.attendance_img = res.data.data.url
					}else{
						this.$message.error(res.data.msg)
					}
				})
			}
		},
		delFile1() {
			this.file1 = '';
			this.attendance_img = '';
			this.$refs.filElem1.value = '';
		},
		getFile2(e) {
			if (this.$refs.filElem2.files.length) {
				this.file2 = this.$refs.filElem2.files[0];
				let myfile = this.$refs.filElem2;
				let files = myfile.files;
				let file = files[0];
				var formData = new FormData();
				formData.append("file", file);
				this.$api.setting.uploadFile(formData).then(res=>{
					if(res.data.code==1){
						this.activity_img = res.data.data.url
					}else{
						this.$message.error(res.data.msg)
					}
				})
			}
		},
		delFile2() {
			this.file2 = '';
			this.$refs.filElem2.value = '';
			this.activity_img = '';
		},
		handlePreview(e){
			this.give_img = e.data.url;
		},
		handleRemove(e){
			this.give_img = '';
		},
		handlePreview2(e){
			this.success_img = e.data.url;
		},
		handleRemove2(e){
			this.success_img = '';
		},
		handleSelectionChange() {},
		// 关闭弹窗
		closeActivity() {
			this.reset();
			this.activityVisible = false;
		},
		handleClose(done) {
			// this.reset();
			done();
		},
		// 分页
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.MyOpenClassList();
		},
		// 分页
		handleSizeChange1(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange1(val) {
			this.page1 = val;
			this.OpenClassList();
		},
		// 选中
		handleSelectionChange(val) {
			let arr = [];
			for (let i in val) {
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids);
		},
		// 数据重置
		reset() {
			this.plan = '';
			this.give_img = '';
			this.success_img = '';
			this.media_img = '';
			this.attendance_img = '';
			this.activity_img = '';
			this.summarize = '';
			this.main_department_id = '';
			this.audit = [];
			this.ectype_department_id = '';
			this.ectype = [];
			this.fileList1 = [];
			this.fileList = [];
			this.file1 = '';
			this.$refs.filElem1.value = '';
			this.file2 = '';
			this.$refs.filElem2.value = '';
			this.file = '';
			this.$refs.filElem.value = '';
		},
		// 获取部门列表
		getSectionList() {
			this.$api.setting.sectionList({}).then(res => {
				if (res.data.code == 1) {
					this.sectionList = res.data.data.rows;
				}
			});
		},
		meunLeftTap(index) {
			this.meunLeftIndex = index;
			if (index == 1) {
				this.getLeaveMyProcess(2);
			} else if (index == 2) {
				this.getLeaveMyProcess(1, 1);
			} else if (index == 3) {
				this.getLeaveMyProcess(1, 2);
			}else if(index==0){
				this.MyOpenClassList()
			}
		},
		// 学年列表
		getYearList() {
			this.$api.setting.getSemesterList().then(res => {
				if (res.data.code == 1) {
					this.yearList = res.data.data;
					if (res.data.data.length) {
						if (this.yearList.filter(item => item.is_default == 2).length > 0) {
							this.year = this.yearList.filter(item => item.is_default == 2)[0].id;
						} else {
							this.year = this.yearList[0].id;
						}
					} else {
						this.year = '';
					}
					this.getSemesterList();
				}
			});
		},
		changeYear(e) {
			this.year = e;
			this.getSemesterList();
		},
		// 学期列表
		getSemesterList() {
			this.$api.setting.getXueQiList({ year_id: this.year }).then(res => {
				if (res.data.code == 1) {
					this.semesterList = res.data.data;
					if (res.data.data.length) {
						if (this.semesterList.filter(item => item.is_default == 2).length > 0) {
							this.semester = this.semesterList.filter(item => item.is_default == 2)[0].id;
						} else {
							this.semester = this.semesterList[0].id;
						}
					} else {
						this.semester = '';
					}
					this.page = 1;
					this.MyOpenClassList()
				}
			});
		},
		changeSemester(e) {
			this.semester = e;
		},
		mainChanage(e) {
			this.userList(e);
		},
		ectypeChanage(e) {
			this.userList2(e);
		},
		// 人员列表
		userList(id) {
			let data = {
				// school_campus_id: this.condition,
				school_department_id: id
			};
			this.$api.setting
				.userList({
					filter: JSON.stringify(data)
				})
				.then(res => {
					if (res.data.code == 1) {
						this.peopleList = res.data.data.rows;
					}
				});
		},
		// 人员列表
		userList2(id) {
			let data = {
				// school_campus_id: this.condition,
				school_department_id: id
			};
			this.$api.setting
				.userList({
					filter: JSON.stringify(data)
				})
				.then(res => {
					if (res.data.code == 1) {
						this.peopleList2 = res.data.data.rows;
					}
				});
		},
		// 添加公开课
		submit() {
			let data = {
				// campusid:this.condition,
				plan:this.plan,
				give_img:this.give_img,
				success_img:this.success_img,
				media_img:this.media_img,
				attendance_img:this.attendance_img,
				activity_img:this.activity_img,
				summarize:this.summarize,
				main_department_id:this.main_department_id,
				audit:this.audit,
				ectype_department_id:this.ectype_department_id,	
				ectype:this.ectype
			};
			this.$api.rule.addOpenClass(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('添加成功')
					this.reset()
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		// 获取我的公开课申请列表
		MyOpenClassList() {
			let data = {
				// campusid:this.condition,
				page:this.page,
				limit:this.limit
			};
			this.$api.rule.MyOpenClassList(data).then(res=>{
				if(res.data.code==1){
					this.Mylist = res.data.data.rows;
					this.total = res.data.data.total;
				}
			})
		},
		// 删除请假申请
		delActivit() {
			let _this = this;
			if (this.ids.length) {
				this.$confirm('确定要删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(() => {
					
				});
			} else {
				this.$message.error('请选择要删除的数据');
			}
		},
		// 详情
		openDetails(e) {
			this.openClassDetails(e.id)
		},
		// 公开课open_class
		openClassDetails(id) {
			this.$api.rule.openClassDetails({ id: id }).then(res => {
				if (res.data.code == 1) {
					this.info = res.data.data;
					this.activityVisible1 = true;
				}
			});
		},
		// 获取抄送、审核列表
		getLeaveMyProcess(type, status) {
			let data = {
				type: type,
				page:this.page1,
				limit:this.limit
			};
			if (status) {
				data.filter = JSON.stringify({'status':status});
			}
			this.$api.rule.OpenClassList(data).then(res=>{
				if(res.data.code==1){
					this.copyToList = res.data.data.rows;
					this.total = res.data.data.total;
				}
			})
		}
	}
};
</script>

<style lang="scss">
#CourseCourse {
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 0.52vw;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.53125vw;
			}
		}
	}
	.content {
		background-color: #ffffff;
		height: 85vh;
		margin-top: 20px;
		border-top: 1px solid #eeeeee;
		.content-left {
			width: 14%;
			border-right: 1px dashed #eeeeee;
			height: 78vh;
			padding: 30px 20px 30px 30px;
			.meun-item {
				cursor: pointer;
				padding: 10px 20px;
				color: #96a2aa;
				.iconfont {
					margin-right: 10px;
				}
			}
			.meun-active {
				background-color: #ddeeff;
				color: #3f9ffe;
			}
		}
		.content-right {
			width: 86%;
			height: 82vh;
			padding: 15px 20px;
			.title {
				padding: 0 0 15px 0;
				.btn {
					background-color: #f5f5f5;
					padding: 5px;
				}
				.btn:hover {
					color: #4998ff;
					background: #e4f1ff;
					cursor: pointer;
				}
			}
		}
	}

	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		height: 600px;
		overflow-y: auto;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 10px;
				.input-lebal {
					width: 25%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.upload-file {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					opacity: 0;
					cursor: pointer;
				}
				.file_box {
					padding: 5px;
					.el-icon-circle-check {
						display: block;
					}
					.el-icon-circle-close {
						display: none;
					}
				}
				.file_box:hover {
					color: #007aff;
					background-color: #f6f8fb;
					.el-icon-circle-check {
						display: none;
					}
					.el-icon-circle-close {
						display: block;
					}
				}
				.el-upload__tip {
					color: #ccc;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
	a{
		text-decoration:none;
	}
}
</style>
